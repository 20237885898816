<template>
  <section class="users">
    <app-modal @close="showModal = false; editUser = null" v-if="showModal">
      <add-user :edit-user="editUser" :is-company-admin="isCompanyAdmin" :companies="companies" @userChanged="onUserChanged"></add-user>
    </app-modal>
    <h1 class="section-header">Användare</h1>
    <div class="row justify-content-between mt-4">
      <div class="row col-md-8">
        <input-field v-model="search" class="col-md-3" @input="searchUsers"  placeholder="Sök ..."></input-field>
        <v-select v-if="!isCompanyAdmin" class="col-md-4 mt-2" @input="searchUsers" v-model="company" :reduce="item => item._id" :options="companies" label="name" placeholder="Företag"></v-select>
      </div>
      <app-button class="btn--secondary mr-3" @click="showModal = true">Lägg till användare</app-button>
    </div>
    <div class="box box--outline mt-3">
      <app-table :head="head">
        <tr v-for="(user, index) in users" :key="index">
          <td class="link-cell" @click="editRow(user)">{{ user.firstname + ' ' + user.lastname }}</td>
          <td>{{ user.company.name }}</td>
          <td>{{ user.username }}</td>
          <td>{{ user.phone }}</td>
        </tr>
      </app-table>
    </div>
    <div class="d-flex justify-content-end mt-5">
      <app-pagination
          :current-page="currentPage"
          :total-pages="totalPages"
          @pageChanged="onPageChanged"
      >
      </app-pagination>
    </div>
  </section>
</template>

<script>
import AppTable from "@/components/shared/AppTable";
import usersHttp from '@/http/users';
import AppIcon from "@/components/shared/AppIcon";
import AppButton from "@/components/shared/AppButton";
import AppModal from "@/components/shared/AppModal";
import AddUser from "@/components/users/AddUser";
import companiesHttp from "@/http/companies";
import {mapGetters} from "vuex";
import {getTotalPages} from "@/utils/pagination";
import AppPagination from "@/components/shared/AppPagination";

export default {
  components: {AppPagination, AddUser, AppModal, AppButton, AppIcon, AppTable},
  data() {
    return {
      head: [
        {name: 'Användare'},
        {name: 'Företag'},
        {name: `E-post`},
        {name: 'Telefonnummer'},
      ],
      editUser: null,
      users: [],
      showModal: false,
      companies: [],
      currentPage: 1,
      totalPages: 0,
      search: null,
      company: null
    }
  },
  computed: {
    ...mapGetters([
      'isCompanyAdmin'
    ])
  },
  methods: {
    async getUsers() {
      try {
        const {users, totalCount} = await usersHttp.getUsers(10, this.currentPage, this.search, this.company);
        this.users = users;

        this.totalPages = getTotalPages(totalCount);
      } catch (e) {}
    },
    async onUserChanged() {
      this.showModal = false;
      this.editUser = null;
      await this.getUsers();
    },
    editRow(user) {
      this.editUser = user;
      this.showModal = true;
    },
    async getCompanies() {
      try {
        const {companies} = await companiesHttp.getCompanies();
        this.companies = companies;
      } catch (e) {
        this.$notify.error('Något gick fel!')
      }
    },
    searchUsers() {
      this.currentPage = 1;
      this.getUsers();
    },
    getCompanyName(id) {
      const company = this.companies.find(item => item._id === id);
      return company ? company.name : '-'
    },
    onPageChanged(page) {
      this.currentPage = page;
      this.getUsers();
    }
  },
  async created() {
    if(!this.isCompanyAdmin) {
      await this.getCompanies();
    }

    await this.getUsers();
  }
}
</script>

<style lang="scss">
.users {

}
</style>
