<template>
  <div class="app-toggle">
      <div class="app-toggle__item" @click="$emit('input', true)" :class="{'active': value}">{{leftValue}}</div>
      <div class="app-toggle__item" @click="$emit('input', false)" :class="{'active': !value}">{{rightValue}}</div>
  </div>
</template>

<script>
export default {
  props: {
    leftValue: String,
    rightValue: String,
    value: Boolean
  }
}
</script>

<style lang="scss" scoped>
.app-toggle {
  border-radius: 30px;
  height: 20px;
  @include flexbox;
  @include align-items(center);
  font-size: 13px;
  color: var(--color-gray-blueish);

  &__item {
    padding: 0 10px;
    border-radius: 30px;
    cursor: pointer;
  }

  .active {
    border: 1px solid var(--color-secondary);
    color: var(--color-secondary);
  }
}
</style>
