<template>
  <div class="add-user">

   <validation-observer ref="userForm" v-slot="{validated}">
     <h1 class="section-header">Användarinformation</h1>

     <div class="row mt-4">
       <validation-provider rules="required" class="col-md-6" v-slot="{invalid}">
         <input-field v-model="form.firstname" :class="{'error': invalid && validated}" label="Förnamn*"/>
       </validation-provider>
       <validation-provider rules="required" class="col-md-6" v-slot="{invalid}">
         <input-field label="Efternamn*" :class="{'error': invalid && validated}" v-model="form.lastname"/>
       </validation-provider>
       <validation-provider  rules="required|email" class="col-12 mt-4 mb-2" v-slot="{invalid}">
         <input-field :disabled="!!editUser" label="E-post*" :class="{'error': invalid && validated}" v-model="form.email"/>
       </validation-provider>
       <validation-provider class="col-8 mt-4">
         <input-field label="Telefonnummer" v-model="form.phone"/>
       </validation-provider>
       <div class="col-4 mt-4">
         <input-field label="Körkortstyp" v-model="form.driverLicense"/>
       </div>
     </div>

     <h1 class="section-header mt-5">Roller & Fordonstyp</h1>

     <div class="box box--outline user-note mt-2">
       Om administratören ska ha möjlighet att vara förare för ett eller flera
       fordon behöver du välja både Administratör och Användare under Roll.
     </div>

     <div class="row mt-4">
       <validation-provider rules="required" v-slot="{invalid}" class="col-md-6 select-item" v-if="!isCompanyAdmin">
         <label>Företag*</label>
         <v-select :options="companies" :class="{'error': invalid && validated}" :reduce="item => item._id" label="name" class="mt-2" v-model="form.company" />
       </validation-provider>
       <validation-provider rules="required" v-slot="{invalid}" class="col-md-6 select-item">
         <label>Roll*</label>
         <v-select @input="roleLimiter" :disabled="!!editUser" class="mt-2" roles="" :class="{'error': invalid && validated}" multiple label="translation" :reduce="item => item.key" :options="roles" v-model="form.roles" />
       </validation-provider>
     </div>

     <h1 class="section-header mt-4" v-if="showVehicles">Fordon</h1>

     <div class="row mt-4 align-items-start" v-if="showVehicles">
       <div class="col-md-6 select-item">
         <label>Fordon</label>
         <v-select
             :options="vehiclesForSelectedCompany"
             multiple label="licencePlate"
             class="mt-2"
             v-model="form.vehicles"
             append-to-body :calculate-position="withPopper"
         >
           <template #no-options>Inga tillgängliga val.</template>
         </v-select>
       </div>
       <div class="col-md-6" v-if="form.vehicles.length > 0">
         <div class="mb-3" style="font-weight: 600">Välj fordonstyp</div>
         <div v-for="(vehicle, index) in form.vehicles" :key="index">
           <div class="vehicle-title">{{vehicle.licencePlate}}</div>
           <div class="mt-1 mb-3"><app-toggle @input="changeVehicleCategory(vehicle, $event)" :value="vehicle.category === 'private'" left-value="Förmånsbil" right-value="Företagsbil"></app-toggle></div>
         </div>
       </div>
     </div>
   </validation-observer>

    <div class="box box--outline user-note mt-3" v-if="form.vehicles.length > 0">
      <h2>Vilken fordonstyp ska jag välja?</h2>
      <h3 class="mb-1">Företagsbil</h3>
      Denna typ av fordon registrerar alla resor som <b>tjänsteresor</b>, både föraren och företagets administratörer kan se och exportera samtliga tjänsteresor. Endast föraren kan se och exportera eventuella privatresor.
      <h3 class="mb-1">Förmånsbil</h3>
      Denna typ av fordon registrerar alla resor som <b>privatresor</b>, både föraren och företagets administratörer kan se och exportera samtliga tjänsteresor. Endast föraren kan se och exportera sina privatresor.
      <br><br>
      Föraren kan alltid välja mellan tjänsteresa och privatresa i mobilappen eller vid inloggat läge på datorn.
    </div>


    <div class="d-flex justify-content-end mt-5">
      <app-button v-if="editUser" @click="deleteUser" class="btn--red mr-2">Radera</app-button>
      <app-button :loading="loading" class="btn--secondary" @click="addUser">
        <template v-if="!editUser">Lägg till</template>
        <template v-else>Spara</template>
      </app-button>
    </div>
  </div>
</template>

<script>
import AppButton from "@/components/shared/AppButton";
import usersHttp from '@/http/users';
import vehicleHttp from '@/http/vehicles';

import {userCanManageRoles, userRoles, userRolesTypes} from "@/utils/user-roles";

import {intersection} from 'lodash'
import {selectMixin} from "@/mixins/select-proper.mixin";
import AppToggle from "@/components/shared/AppToggle";
import {createSuccess, updateSuccess} from "@/utils/translations";
import {cancelButton, deleteDialogMessage, errorNotification, yesButton} from "@/utils/translations";
import {makeErrorMessage} from "@/utils/error.helper";

export default {
  props: ['companies', 'isCompanyAdmin', 'editUser'],
  components: {AppToggle, AppButton},
  mixins: [selectMixin],
  data() {
    return {
      form: {
        firstname: '',
        lastname: '',
        email: '',
        driverLicense: '',
        phone: '',
        company: null,
        roles: null,
        vehicles: []
      },
      vehicles: [],
      vehicleCategories: [],
      loading: false,
    }
  },
  computed: {
    roles() {
      const canManageRolesArray = [];
      const loggedInUserRoles = this.$store.getters.getUserRoles;

      loggedInUserRoles.forEach(item => {
        canManageRolesArray.push(userCanManageRoles[item])
      })
      const roles = intersection(...canManageRolesArray);
      return userRoles.filter(item => roles.includes(item.key))
    },
    showVehicles() {
      return this.form.roles?.includes(userRolesTypes.SIMPLE_USER)
    },
    vehiclesForSelectedCompany() {
      return this.vehicles.filter(item => item.company._id === this.form.company)
    },
    user() {
      return this.$store.state.user
    }
  },
  methods: {
    async addUser() {
      if(this.loading) return;

      try {
        const isValid = await this.$refs.userForm.validate();
        if(!isValid) return;

        this.loading = true;

        let vehicles = this.form.vehicles.map(item => {
          return {
            licencePlate: item.licencePlate,
            category: item.category
          }
        })

        if(this.editUser) {
          await usersHttp.editUser({...this.form, vehicles});
          this.$notify.success(updateSuccess('Användaren'));
        } else {
          await usersHttp.addUser({...this.form, vehicles});
          this.$notify.success(createSuccess('Användaren'));

        }

        this.$emit('userChanged');
      } catch (e) {
        const message = makeErrorMessage(e)
        this.$notify.error(message);
      } finally {
        this.loading = false;
      }
    },
    changeVehicleCategory(vehicle, value) {
      vehicle.category = value ? 'private' : 'business'
    },
    async deleteUser() {
      try {
        await this.$confirm('', deleteDialogMessage, {
          confirmButtonText: yesButton,
          cancelButtonText: cancelButton,
          type: 'warning'
        })
      } catch (e) {
        return;
      }

      try {
        await usersHttp.deleteUser(this.form.email);

        this.$notify.success('Användaren togs bort!')
        this.$emit('userChanged');
      } catch (e) {
        this.$notify.error(errorNotification);
      }
    },
    roleLimiter(e) {
      if(e.length > 2) {
        this.$notify.warning('Det går inte att lägga till mer än två roller på samma användare!')
        e.pop()
      }
    }
  },
  async created() {
    if(this.isCompanyAdmin) {
      this.form.company = this.user.company;
    }

    if(this.editUser) {
      Object.assign(this.form, this.editUser);
      if(this.editUser.company) {
        this.form.company = this.editUser.company._id;
      }
    }

    vehicleHttp.getVehicles({bindStatus: 'unbound', boxBindStatus: 'bound'}).then(response => {
      const {vehicles} = response;

      this.vehicles = vehicles.map(item => ({...item, category: 'business'}));
    })
  }
}
</script>

<style scoped>
.vehicle-title {
  font-size: 14px;
  padding-left: 10px;
  font-weight: 600;
}
.user-note {
  text-align: justify;
  font-size: 14px;
  line-height: 1.5;
  color: var(--color-gray);
  background: var(--color-gray-xLight);
}
</style>
