import axios from 'axios';

export default ({
  getUsers(limit = null, page = null, search = null, company = null) {
    const params = {
      limit,
      skip: page ? page - 1 : null,
      search,
      company
    }

    return axios.get('/users', {params})
  },

  addUser(user) {
    return axios.post('/users', {...user})
  },
  editUser(user) {
    return axios.put('/users', {...user})
  },
  deleteUser(id) {
    return axios.delete(`/users/${id}`)
  }
})
